.root {
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.tag {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 20px;
}

.removeTagButton {
  background: none;
  border: none;
  cursor: pointer;
  color: blue;
  font-weight: bold;
  margin-right: 5px;
  padding: 0;
  font-size: 16px;
  line-height: 1;
  transition: color 0.3s;
}

.removeTagButton:hover {
  color: darkred;
}

.removeTagButton:focus {
  outline: none;
}
