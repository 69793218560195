.root {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.inputError {
  border: var(--borderErrorField);
}
